<template>
  <div>
    <h1 style="margin-left: 0%; margin-bottom: 4%">Etat des attestations</h1>
    <a-row type="flex" justify="space-between" class="table_head">
      <a-col :span="7"></a-col>
      <a-col :span="6" style="margin-bottom: 2%">
        <search-component
          v-on:change="searchAttestation($event)"
        ></search-component>
      </a-col>
    </a-row>
    <DisplayAttestationDetail
      :visible="is_display_details_visible"
      :attestation="attestation"
      @cancel="closeDisplayDetailsModal"
    />
    <AttestationDecisionForm
      ref="DemandeAttestationForm"
      :processing="attestationFormProcessing"
      @close="hideAttestationDecisionForm"
      @create="handleDecisionChange"
      :visible="attestationDecisionFormVisible"
    />
    <a-spin :spinning="processing">
      <a-table
        bordered
        v-if="dataSource.length"
        :dataSource="dataSource"
        :columns="columns"
        :pagination="false"
        :rowKey="(record) => record.id"
      >
        <template slot="row_index" slot-scope="text, record, index">
          <p>{{ index + 1 }}</p>
        </template>
        <template slot="attestation" slot-scope="text, record">{{
          record.type_attestation.nom_fr
        }}</template>
        <template slot="operation" slot-scope="text, record">
          <a-popconfirm
            v-if="record.etat === ETAT_DEMANDE_ATTESTATION_ACCEPTER"
            :title="SUPPRIMER_LIGNE"
            @confirm="() => onDelete(record.id)"
          >
            <a-tooltip placement="top">
              <template slot="title">
                <span>Supprimer</span>
              </template>
              <a-icon
                type="delete"
                class="action_icons"
                theme="twoTone"
                twoToneColor="#eb2f96"
              />
            </a-tooltip>
          </a-popconfirm>
          <a-tooltip placement="top" v-if="record.etat === ETAT_DEMANDE_ATTESTATION_ACCEPTER">
            <template slot="title">
              <span>Imprimer</span>
            </template>
            <a-icon
              type="printer"
              @click="getDocument(record)"
              class="action_icons"
              theme="twoTone"
            />
          </a-tooltip>

          <a-tooltip  v-if="record.etat !== ETAT_DEMANDE_ATTESTATION_ACCEPTER">
            <a-icon
              type="bell"
              class="action_icons"
              theme="twoTone"
              twoToneColor="#52c41a"
              @click="showAttestationDecisionForm(record)"
            />
            <template slot="title">Valider et notifier l'adherent</template>
          </a-tooltip>

          <a-tooltip>
            <a-icon
              type="eye"
              class="action_icons"
              @click="displayObservation(record)"
            />
            <template slot="title">Detail demande d'attestation</template>
          </a-tooltip>
          <a-tooltip placement="top">
            <template slot="title">
              <span>Voir toutes les attestations</span>
            </template>
            <a-icon
              type="bars"
              v-on:click="goToUserAttestations(record.user.id)"
              class="action_icons"
              theme="twoTone"
              twoToneColor="#52c41a"
            />
          </a-tooltip>
        </template>
        <template slot="etat" slot-scope="text, record">
          <a-tooltip v-if="record.etat !== ETAT_DEMANDE_ATTESTATION_ACCEPTER" placement="left">
            <a-icon
              type="close-circle"
              theme="twoTone"
              twoToneColor="#eb2f96"
            />
            <template slot="title"
              >La demande n'a pas encore été validée</template
            >
          </a-tooltip>
          <a-tooltip v-else placement="left">
            <a-icon
              type="check-circle"
              theme="twoTone"
              twoToneColor="#52c41a"
            />
            <template slot="title">La demande est validée</template>
          </a-tooltip>
        </template>
        <template slot="nom_prenom" slot-scope="text, record"
          >{{ record.user.full_name }}
        </template>
        <template slot="dt_demande" slot-scope="text, record"
          >{{ record.created_at | date }}
        </template>
      </a-table>
      <EmptyResult v-else />
      <a-pagination
        v-if="dataSource.length"
        style="margin-top: 1%"
        @change="onPaginationChange"
        @showSizeChange="onPageSizeChange"
        :current="pagination.current"
        :total="pagination.total"
        :pageSize="pagination.pageSize"
        :showSizeChanger="true"
      />
    </a-spin>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import moment from "moment";

import DisplayAttestationDetail from "./DisplayDetails";
// import CreateForm from "./CreateForm";
import AttestationDecisionForm from "./AttestationDecisionForm";
import SearchComponent from "@/components/common/Search";
import EmptyResult from "@/components/common/EmptyResult";
import constants from "@/const/const";
import debounce from "lodash-es/debounce";


const columns = [
  {
    title: "N°",

    scopedSlots: { customRender: "row_index" },
  },
  {
    title: "Matricule",
    dataIndex: "user.matricule",
  },
  {
    title: "Nom & Prénom",
    scopedSlots: { customRender: "nom_prenom" },
  },
  {
    title: "Date demande",
    scopedSlots: { customRender: "dt_demande" },
  },
  {
    title: "Type Attestation",
    dataIndex: "name",
    scopedSlots: { customRender: "attestation" },
  },
  {
    title: "Actions",
    scopedSlots: { customRender: "operation" },
  },
  {
    title: "Etat",
    scopedSlots: { customRender: "etat" },
  },
];
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 12,
      offset: 0,
    },
    sm: {
      span: 12,
      offset: 20,
    },
  },
};

export default {
  name: "AttestationListe",
  created() {
    this.fetchData();
  },
  components: {
    // CreateForm,
    SearchComponent,
    DisplayAttestationDetail,
    EmptyResult,
    AttestationDecisionForm,
  },
  data() {
    this.searchAttestation = debounce(this.searchAttestation, 500);
    return {
      confirmDirty: false,
      autoCompleteResult: [],
      formItemLayout: formItemLayout,
      tailFormItemLayout: tailFormItemLayout,
      columns: columns,
      ModalText: "Content of the modal",
      observation: "",
      attestation: null,
      selectedAttestation : null,
      is_display_details_visible: false,
      attestationFormProcessing: false,
      attestationDecisionFormVisible: false,
      processing: false,
      confirmLoading: false,
      SUPPRIMER_LIGNE: constants.SUPPRIMER_LIGNE,
      VALIDER_LIGNE: constants.VALIDER_LIGNE,
      ADHERENT_PAS_PAYER: constants.ADHERENT_PAS_PAYER,
      DEVALIDER_ADHERENT: constants.DEVALIDER_ADHERENT,
    };
  },
  computed: {
    ...mapState({
      dataSource: (state) => state.attestations.attestations,
      pagination: (state) => state.attestations.pagination,
    }),
  },
  methods: {
    moment,
    fetchData(q = "") {
      this.startProcessing();
      this.getAdditioanlData({ q: q }).finally(() => this.stopProcessing());
    },
    getDocument(record) {
      this.startProcessing();
      this.callPdfGenerator({
        id: record.user.id,
        type: 7, //Attestation
        organisation: record.user.organisation_id,
      })
        .catch(() => this.$_throwAnError())
        .finally(() => this.stopProcessing());
    },
    showAttestationDecisionForm(attestation) {
      this.attestationDecisionFormVisible = true
      this.selectedAttestation = attestation
    },
    hideAttestationDecisionForm() {
      this.attestationDecisionFormVisible = false
      this.selectedAttestation = null
    },
    goToUserAttestations(userId) {
      this.$router.push(`adherents/attestations/${userId}`);
    },
    onPaginationChange(pagination, filters, sorter) {
      this.setPagination({ ...this.pagination, current: pagination });
      this.fetchData();
    },
    onPageSizeChange(current, size) {
      const pager = { ...this.pagination };
      pager.pageSize = size;
      this.setPagination(pager);
      this.fetchData();
    },
    startProcessing() {
      this.processing = true;
    },
    stopProcessing() {
      this.processing = false;
    },
    displayObservation(record) {
      this.attestation = record;
      this.showDisplayDetailsModal();
    },
    searchAttestation(e) {
      this.fetchData(e);
    },
    stopAttestationFormLoading() {
      this.attestationFormProcessing = false;
    },
    startAttestationFormLoading() {
      this.attestationFormProcessing = true;
    },
    closeDisplayDetailsModal() {
      this.is_display_details_visible = false;
    },
    showDisplayDetailsModal() {
      this.is_display_details_visible = true;
    },
    onDelete(id) {
      this.remove(id).then(() => this.fetchData());
    },
    handleDecisionChange() {
     if (this.isUserPaiementSettled(this.selectedAttestation.user)) {
        this.$_throwAnError(`${this.ADHERENT_PAS_PAYER}`, 4);
        return;
      }
      const form = this.$refs.DemandeAttestationForm.form
      let that = this;
      form.validateFields((err, values) => {
        if (!err) {
          this.startAttestationFormLoading();
          this.validateAndNotifyUser({ id : this.selectedAttestation.id, ...values })
            .then(() => {
              that.hideAttestationDecisionForm();
              form.resetFields();
              that.fetchData();
            })
            .finally(() => that.stopAttestationFormLoading());
        }
      });
    },
    handleConfirmBlur(e) {
      this.confirmDirty = this.confirmDirty || !!e.target.value;
    },
    ...mapMutations({
      setPagination: "setAttestationPagination",
      resetPagination: "setAttestationDefaultPagination",
    }),
    ...mapActions({
      getAdditioanlData: "fetchAttestations",
      remove: "destroyAttestation",
      validateAndNotifyUser: "changeAttestationState",
      callPdfGenerator: "callPdfGenerator",
    }),
  },
};
</script>
<style scoped>
.action_icons {
  margin: 5px;
  font-size: large;
}
</style>
