var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { title: _vm.title, visible: _vm.visible },
      on: { cancel: _vm.handleCancel, ok: _vm.handleOk }
    },
    [
      _c("h3", [_vm._v(" Organisme ")]),
      _vm._v(" " + _vm._s(_vm.organisme) + "\n  "),
      _c("h3", [_vm._v("Attestation : ")]),
      _vm._v(_vm._s(_vm.attestationLabel) + "\n  "),
      _c("h3", [_vm._v("Observation : ")]),
      _vm._v(_vm._s(_vm.observation) + "\n\n")
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }