<template>
  <a-modal
    title="Voulez-vous valider et notifier cette demande d'attestation ?"
    :visible="visible"
    @cancel="handleCancel"
    okText="Valider"
    @ok="handleOk"
    :okButtonProps="{ props: { htmlType: 'submit' } }"
  >
    <a-form
      :layout="formLayout"
      id="attestation_decision_form"
      name="attestation_decision_form"
      class="adherent_form"
      :form="form"
    >
      <a-row>
        <a-col :span="18">
          <a-form-item v-bind="formItemLayout" label="Référence">
            <a-input
              placeholder="0317/SORCDT/CEPQ/2017"
              v-decorator="[
                'ref',
                {
                  rules: [
                    {
                      required: false,
                      message: 'Référence est obligatoire!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <template slot="footer">
      <a-button
        key="submit"
        type="primary"
        :loading="processing"
        @click="handleOk"
        >Valider</a-button
      >
    </template>
  </a-modal>
</template>
<script>
export default {
  name: "AttestationDecisionForm",
  data() {
    return {
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 14 },
        },
      },
      form: this.$form.createForm(this),
    };
  },
  props: {
    processing: { type: Boolean, default: () => false },
    visible: { type: Boolean, default: () => false },
  },
  methods: {
    handleClose() {
      this.$emit("close");
    },
    handleCancel() {
      this.$emit("close");
    },
    handleOk() {
      this.$emit("create");
    },
  },
};
</script>
