<template>
  <a-modal :title="title" :visible="visible" @cancel="handleCancel" @ok="handleOk">
    <h3> Organisme </h3> {{organisme}}
    <h3>Attestation : </h3>{{attestationLabel}}
    <h3>Observation : </h3>{{observation}}

  </a-modal>
</template>
<script>
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 }
  }
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 12,
      offset: 0
    },
    sm: {
      span: 12,
      offset: 20
    }
  }
};
export default {
  name: "DisplayAdherentDetails",
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  created() {

  },
  data() {
    return {
      formItemLayout,
      tailFormItemLayout
    }
  },
  props: ["visible", "attestation"],
  computed: {
    title: function () {
      return this.attestation ?
        `${this.attestation.user.nom_fr} ${this.attestation.user.prenom_fr}`
        : '';
    },
    organisme: function () {
      return this.attestation ? (
        this.attestation.organisme ?
          this.attestation.organisme.nom_fr
          : null
      ) : null
    },
    attestationLabel: function () {
      return this.attestation ? (
        this.attestation.type_attestation ?
          this.attestation.type_attestation.nom_fr
          : null
      ) : null
    },
    observation: function () { return this.attestation ? this.attestation.observation : null }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    handleCancel() {
      this.$emit("cancel");
    },
    handleOk() {
      this.$emit("cancel");
    }
  }
};
</script>