<template>
    <liste-attestations></liste-attestations>    
</template>

<script>
import ListeAttestations from "./ListeAttestations";
export default {
  name: "AttestationsIndex",
  components: { ListeAttestations }
};
</script>

<style scoped>
</style>
