var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "h1",
        { staticStyle: { "margin-left": "0%", "margin-bottom": "4%" } },
        [_vm._v("Etat des attestations")]
      ),
      _c(
        "a-row",
        {
          staticClass: "table_head",
          attrs: { type: "flex", justify: "space-between" }
        },
        [
          _c("a-col", { attrs: { span: 7 } }),
          _c(
            "a-col",
            { staticStyle: { "margin-bottom": "2%" }, attrs: { span: 6 } },
            [
              _c("search-component", {
                on: {
                  change: function($event) {
                    return _vm.searchAttestation($event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("DisplayAttestationDetail", {
        attrs: {
          visible: _vm.is_display_details_visible,
          attestation: _vm.attestation
        },
        on: { cancel: _vm.closeDisplayDetailsModal }
      }),
      _c("AttestationDecisionForm", {
        ref: "DemandeAttestationForm",
        attrs: {
          processing: _vm.attestationFormProcessing,
          visible: _vm.attestationDecisionFormVisible
        },
        on: {
          close: _vm.hideAttestationDecisionForm,
          create: _vm.handleDecisionChange
        }
      }),
      _c(
        "a-spin",
        { attrs: { spinning: _vm.processing } },
        [
          _vm.dataSource.length
            ? _c("a-table", {
                attrs: {
                  bordered: "",
                  dataSource: _vm.dataSource,
                  columns: _vm.columns,
                  pagination: false,
                  rowKey: function(record) {
                    return record.id
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "row_index",
                      fn: function(text, record, index) {
                        return [_c("p", [_vm._v(_vm._s(index + 1))])]
                      }
                    },
                    {
                      key: "attestation",
                      fn: function(text, record) {
                        return [_vm._v(_vm._s(record.type_attestation.nom_fr))]
                      }
                    },
                    {
                      key: "operation",
                      fn: function(text, record) {
                        return [
                          record.etat === _vm.ETAT_DEMANDE_ATTESTATION_ACCEPTER
                            ? _c(
                                "a-popconfirm",
                                {
                                  attrs: { title: _vm.SUPPRIMER_LIGNE },
                                  on: {
                                    confirm: function() {
                                      return _vm.onDelete(record.id)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "a-tooltip",
                                    { attrs: { placement: "top" } },
                                    [
                                      _c("template", { slot: "title" }, [
                                        _c("span", [_vm._v("Supprimer")])
                                      ]),
                                      _c("a-icon", {
                                        staticClass: "action_icons",
                                        attrs: {
                                          type: "delete",
                                          theme: "twoTone",
                                          twoToneColor: "#eb2f96"
                                        }
                                      })
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          record.etat === _vm.ETAT_DEMANDE_ATTESTATION_ACCEPTER
                            ? _c(
                                "a-tooltip",
                                { attrs: { placement: "top" } },
                                [
                                  _c("template", { slot: "title" }, [
                                    _c("span", [_vm._v("Imprimer")])
                                  ]),
                                  _c("a-icon", {
                                    staticClass: "action_icons",
                                    attrs: {
                                      type: "printer",
                                      theme: "twoTone"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.getDocument(record)
                                      }
                                    }
                                  })
                                ],
                                2
                              )
                            : _vm._e(),
                          record.etat !== _vm.ETAT_DEMANDE_ATTESTATION_ACCEPTER
                            ? _c(
                                "a-tooltip",
                                [
                                  _c("a-icon", {
                                    staticClass: "action_icons",
                                    attrs: {
                                      type: "bell",
                                      theme: "twoTone",
                                      twoToneColor: "#52c41a"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.showAttestationDecisionForm(
                                          record
                                        )
                                      }
                                    }
                                  }),
                                  _c("template", { slot: "title" }, [
                                    _vm._v("Valider et notifier l'adherent")
                                  ])
                                ],
                                2
                              )
                            : _vm._e(),
                          _c(
                            "a-tooltip",
                            [
                              _c("a-icon", {
                                staticClass: "action_icons",
                                attrs: { type: "eye" },
                                on: {
                                  click: function($event) {
                                    return _vm.displayObservation(record)
                                  }
                                }
                              }),
                              _c("template", { slot: "title" }, [
                                _vm._v("Detail demande d'attestation")
                              ])
                            ],
                            2
                          ),
                          _c(
                            "a-tooltip",
                            { attrs: { placement: "top" } },
                            [
                              _c("template", { slot: "title" }, [
                                _c("span", [
                                  _vm._v("Voir toutes les attestations")
                                ])
                              ]),
                              _c("a-icon", {
                                staticClass: "action_icons",
                                attrs: {
                                  type: "bars",
                                  theme: "twoTone",
                                  twoToneColor: "#52c41a"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.goToUserAttestations(
                                      record.user.id
                                    )
                                  }
                                }
                              })
                            ],
                            2
                          )
                        ]
                      }
                    },
                    {
                      key: "etat",
                      fn: function(text, record) {
                        return [
                          record.etat !== _vm.ETAT_DEMANDE_ATTESTATION_ACCEPTER
                            ? _c(
                                "a-tooltip",
                                { attrs: { placement: "left" } },
                                [
                                  _c("a-icon", {
                                    attrs: {
                                      type: "close-circle",
                                      theme: "twoTone",
                                      twoToneColor: "#eb2f96"
                                    }
                                  }),
                                  _c("template", { slot: "title" }, [
                                    _vm._v(
                                      "La demande n'a pas encore été validée"
                                    )
                                  ])
                                ],
                                2
                              )
                            : _c(
                                "a-tooltip",
                                { attrs: { placement: "left" } },
                                [
                                  _c("a-icon", {
                                    attrs: {
                                      type: "check-circle",
                                      theme: "twoTone",
                                      twoToneColor: "#52c41a"
                                    }
                                  }),
                                  _c("template", { slot: "title" }, [
                                    _vm._v("La demande est validée")
                                  ])
                                ],
                                2
                              )
                        ]
                      }
                    },
                    {
                      key: "nom_prenom",
                      fn: function(text, record) {
                        return [
                          _vm._v(_vm._s(record.user.full_name) + "\n      ")
                        ]
                      }
                    },
                    {
                      key: "dt_demande",
                      fn: function(text, record) {
                        return [
                          _vm._v(
                            _vm._s(_vm._f("date")(record.created_at)) +
                              "\n      "
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2543718240
                )
              })
            : _c("EmptyResult"),
          _vm.dataSource.length
            ? _c("a-pagination", {
                staticStyle: { "margin-top": "1%" },
                attrs: {
                  current: _vm.pagination.current,
                  total: _vm.pagination.total,
                  pageSize: _vm.pagination.pageSize,
                  showSizeChanger: true
                },
                on: {
                  change: _vm.onPaginationChange,
                  showSizeChange: _vm.onPageSizeChange
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }